import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  HttpClient,
  HttpBackend,
} from '@angular/common/http';
import {
  importProvidersFrom,
  APP_INITIALIZER,
  FactoryProvider,
  isDevMode,
} from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
  MsalInterceptor,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { EffectsModule } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { AppComponent } from './app/app.component';
import { AppHttpInterceptor } from './app/shared/interceptors/app-http-interceptor.';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { InitialStartupService } from './app/auth/initial-startup.service';
import { MsalConfig } from './app/auth/msal.config';
import { licenseKey } from './app/devextreme-key';
import config from 'devextreme/core/config';
import { appRoutes } from './app/app-routing';
import { uiReducer } from './app/store/ui/ui.reducer';
import { userReducer } from './app/store/user/user.reducer';
import { clinicsReducer } from './app/store/clinics/clinics.reducer';
import { initialDataReducer } from './app/store/initalData/initial-data.reducer';
import { UserEffects } from './app/store/user/user.effects';
import { LoaderInterceptorProvider } from './app/shared/interceptors/loader.interceptor';
import { AuthInterceptorService } from './app/auth/auth-interceptor.service';
import { GraphService } from './app/auth/graph.service';
import { LoadingSpinnerComponent } from './app/shared/loading-spinner/loading-spinner.component';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ErrorInterceptorProvider } from './app/shared/interceptors/error.interceptor';
import { provideServiceWorker } from '@angular/service-worker';
//import { SharedDynamicModule } from './app/shared/dynamic/dynamic.module';

config({ licenseKey });
export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: {
      prefix: string;
      suffix: string;
    }[]
  ) {}

  public getTranslation(lang: string): Observable<any> {
    //const headers = new HttpHeaders().set(InterceptorSkipHeader, '');

    const requests = this.resources.map((resource) => {
      const path = resource.prefix + lang + resource.suffix;
      return this.http.get(path /*, { headers }*/).pipe(
        catchError((res) => {
          console.error(
            'Something went wrong for the following translation file:',
            path
          );
          console.error(res.message);
          return of({});
        })
      );
    });

    return forkJoin(requests).pipe(
      map((responses) => Object.assign({}, ...responses)) // Merge all responses into one object
    );
  }
}
export function httpLoaderFactory(httpHandler: HttpBackend): any {
  return new MultiTranslateHttpLoader(new HttpClient(httpHandler), [
    { prefix: './assets/i18n/', suffix: '.json' },
    // { prefix: './assets/i18n/CNESST/', suffix: '.json' },
    // { prefix: './assets/i18n/Teleplan/', suffix: '.json' },
    // { prefix: './assets/i18n/Hcai/', suffix: '.json' },
    // { prefix: './assets/i18n/WCB/', suffix: '.json' },
  ]);
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function appInitializerFn(configService: InitialStartupService) {
  return () => configService.initApplication();
}
export function msalInstanceFactory(
  appConfigService: InitialStartupService
): IPublicClientApplication {
  if (appConfigService.isSSOLogin) {
    return MSALInstanceFactory();
  } else {
    return FakeMSALInstanceFactory();
  }
}
export function loggerCallback(logLevel: LogLevel, message: string): void {
  //console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: MsalConfig.clientId,
      authority: MsalConfig.authority,
      redirectUri: MsalConfig.redirectUri,
      postLogoutRedirectUri: MsalConfig.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loadFrameTimeout: 30000,
      tokenRenewalOffsetSeconds: 60,
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        piiLoggingEnabled: false,
        logLevel: LogLevel.Verbose,
      },
    },
  });
}
export function FakeMSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '',
      authority: '',
      redirectUri: '',
      postLogoutRedirectUri: '',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loadFrameTimeout: 30000,
      tokenRenewalOffsetSeconds: 60,
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        piiLoggingEnabled: false,
        logLevel: LogLevel.Verbose,
      },
    },
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap: Map<string, Array<string>> = new Map<
    string,
    Array<string>
  >();
  protectedResourceMap.set(
    MsalConfig.redirectUri,
    MsalConfig.loginRequest.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...MsalConfig.loginRequest.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

const msalProvider: FactoryProvider = {
  provide: MSAL_INSTANCE,
  useFactory: msalInstanceFactory,
  deps: [InitialStartupService],
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      LoadingSpinnerComponent,
      TranslateService,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
      StoreModule.forRoot({
        ui: uiReducer,
        user: userReducer,
        clinics: clinicsReducer,
        initalData: initialDataReducer,
      }),
      EffectsModule.forRoot([UserEffects])
    ),
    provideStore(
      {
        ui: uiReducer,
        user: userReducer,
        clinics: clinicsReducer,
        initalData: initialDataReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionWithinNgZone: true,
        },
      }
    ),
    ErrorInterceptorProvider,
    LoaderInterceptorProvider,
    // AuthGuard,
    // ReferenceTablesHttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    msalProvider,
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GraphService,
    InitialStartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [InitialStartupService],
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      appRoutes,
      //withPreloading(QuicklinkStrategy),
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: true, //!isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).
    //TODO: provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
  ],
}).catch((err) => console.error(err));
